import { CacheProvider } from '../cache';

class WebCacheProvider implements CacheProvider {
  private cacheProvider;

  constructor(cache: Storage = localStorage) {
    this.cacheProvider = cache;
  }

  get(key: string): Promise<string> {
    const content = this.cacheProvider.getItem(key);
    if (content !== null) {
      return Promise.resolve(content);
    }

    return Promise.reject('Key not found');
  }

  set(key: string, value: string): Promise<void> {
    this.cacheProvider.setItem(key, value);

    return Promise.resolve();
  }

  del(key: string): Promise<void> {
    if (this.cacheProvider.getItem(key)) {
      this.cacheProvider.removeItem(key);
      return Promise.resolve();
    }
    return Promise.reject('Key not found');
  }

  keys(): Promise<Array<string>> {
    return Promise.resolve(Object.keys(this.cacheProvider));
  }

  isAvailable(): Promise<boolean> {
    try {
      const x = '__storage_test__';
      localStorage.setItem(x, x);
      localStorage.removeItem(x);
      return Promise.resolve(true);
    } catch (e) {
      return Promise.resolve(false);
    }
  }
}

export default WebCacheProvider;
