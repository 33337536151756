<template>
  <div id="widget-container" ref="widget" class="max-h-full w-full select-none">
    <linear-gradient-definition :id="gradientID" />
    <div
      v-if="options.rows"
      class="grid auto-cols-[0] auto-rows-[0] overflow-hidden"
      :style="[gridColsStyle, gridRowsStyle]"
    >
      <component
        :is="widgetComponent"
        v-for="(item, index) in items"
        :key="index"
        :survey="item"
        class="m-10 cursor-pointer"
        :offer="item"
        @open-offerwall="() => openOfferwall(index)"
        @close-widget="closeWidget"
      />

      <widget-design-offer-show-more-tile
        v-if="design === 'offers'"
        class="m-10 cursor-pointer"
        :icon="firstOfferIcon"
        @open-offerwall="openOffers"
      />
    </div>
    <component
      :is="widgetComponent"
      v-else
      class="box-border inline-flex cursor-pointer"
      :survey="items[0]"
      :offer="items[0]"
      :gradient-id="gradientID"
      @open-offerwall="openFirstSurvey"
      @close-widget="closeWidget"
    />
  </div>
</template>

<script setup lang="ts">
import { useResizeObserver } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, ref, watchEffect } from 'vue';

import { emitter } from '../emitter';
import { useOffersStore } from '../stores/offersStore';
import { useOptionsStore } from '../stores/optionsStore';
import { useSurveysStore } from '../stores/surveysStore';
import { useUserStore } from '../stores/userStore';
import { Offer } from '../types/offer';
import { Survey } from '../types/survey';
import type { WidgetDesign, WidgetOptions, WidgetPosition } from '../types/widget';
import { generateFakeSurveys } from '../utils/surveys';

import WidgetDesignEarnings from './designs/earnings/WidgetDesignEarnings.vue';
import WidgetDesignLeaderboard from './designs/leaderboard/WidgetDesignLeaderboard.vue';
import WidgetDesignOffer from './designs/offers/WidgetDesignOffer.vue';
import WidgetDesignOfferShowMoreTile from './designs/offers/WidgetDesignOfferShowMoreTile.vue';
import WidgetDesignCompact from './designs/surveys/WidgetDesignCompact.vue';
import WidgetDesignFullWidth from './designs/surveys/WidgetDesignFullWidth.vue';
import WidgetDesignNotification from './designs/surveys/WidgetDesignNotification.vue';
import WidgetDesignSimple from './designs/surveys/WidgetDesignSimple.vue';
import LinearGradientDefinition from './LinearGradientDefinition.vue';

const props = defineProps<{
  design: WidgetDesign;
  position: WidgetPosition;
  options: WidgetOptions;
}>();

// Component
const widgetComponent = computed(() => {
  switch (props.design) {
    default:
    case 'compact':
      return WidgetDesignCompact;
    case 'earnings':
      return WidgetDesignEarnings;
    case 'full-width':
      return WidgetDesignFullWidth;
    case 'leaderboard':
      return WidgetDesignLeaderboard;
    case 'notification':
      return WidgetDesignNotification;
    case 'offers':
      return WidgetDesignOffer;
    case 'simple':
      return WidgetDesignSimple;
  }
});

// Styles
const widths: Record<WidgetDesign, number> = {
  compact: 250,
  simple: 280,
  offers: 170,
  'full-width': 500,
  leaderboard: 350,
  notification: 350,
  earnings: 350,
};
const gridColsWidth = props.options.autoWidth ? '1fr' : `${widths[props.design] || '250'}px`;
const gridColsStyle = `grid-template-columns: repeat(auto-fit, minmax(${widths[props.design]}px, ${gridColsWidth})`;
const gridRowsStyle = `grid-template-rows: repeat(${props.options.rows ?? 0}, auto)`;

// Gradient
const gradientID = 'svg-gradient-' + Date.now().toString();
const gradientCssUrl = `url(#${gradientID})`;

// Row Item count
const widget = ref();
const maxItemsPerRow = ref(20);
useResizeObserver(widget, entries => {
  const widgetWidth = widths[props.design];
  const containerWidth = entries[0]?.contentRect.width;

  const maxItems = Math.floor(containerWidth / widgetWidth);

  maxItemsPerRow.value = maxItems > 0 ? maxItems : 1;
});

// Items
const { surveys } = storeToRefs(useSurveysStore());
const { offers } = storeToRefs(useOffersStore());
const computedSurveys = computed<Array<Survey>>(() => {
  if (!props.options.rows) return surveys.value;
  if (!props.options.noFakeSurveys && surveys.value.length < 20)
    return [...surveys.value, ...generateFakeSurveys(20 - surveys.value.length)];
  return surveys.value;
});
const items = computed<Array<Offer> | Array<Survey>>(() => {
  const maxItems = (props.options.rows ?? 1) * maxItemsPerRow.value;
  if (props.design === 'offers') return offers.value.slice(0, maxItems - 1);
  return computedSurveys.value.slice(0, maxItems);
});
const firstOfferIcon = computed(() => (offers.value[0] ? offers.value[0].icon : ''));

// Behaviour
const optionsStore = useOptionsStore();
const openFirstSurvey = () => {
  openOfferwall(0);
};
const openOffers = () => {
  openOfferwall(-1);
};
const openOfferwall = (index: number) => {
  optionsStore.callback('onOfferwallOpen');

  if (typeof props.options.onClick === 'function') {
    props.options.onClick();
  } else {
    const link = index === -1 ? '/offers' : items.value[index].link;
    emitter.emit('open-offerwall', { position: props.position, link });
  }
};
const closeWidget = () => {
  optionsStore.callback('onWidgetClose');
};

// Offer Impression Tracking
const offersStore = useOffersStore();
const userStore = useUserStore();
const integrateRewardImpression = (
  wall_code: string,
  user_id: string,
  sub_ids: Record<string, unknown>,
  container: string
) => {
  const script = document.createElement('script');
  script.src = 'https://api.adgatemedia.com/apps/sdk/sdk.min.js';
  script.async = true;
  script.onload = () => {
    // eslint-disable-next-line no-undef
    const sdk = new OfferWallSDK(wall_code, user_id, sub_ids);
    sdk.registerImpressionHandler(container);
  };
  document.body.appendChild(script);
};

watchEffect(() => {
  if (props.design !== 'offers') return;
  integrateRewardImpression(
    offersStore.offerwallCode,
    userStore.user.userId.toString(),
    { bb_ow: true },
    '#widget-container'
  );
});
</script>

<style lang="scss">
svg.gradient-icon * {
  fill: v-bind(gradientCssUrl);
}
</style>
