import { defineStore } from 'pinia';
import { ref } from 'vue';

import { Api } from '../api';
import { Survey } from '../types/survey';

const useSurveysStore = defineStore('surveys', () => {
  const surveys = ref<Array<Survey>>([]);
  const bestSurvey = ref<Survey>({ uniqueId: '', loi: 0, value: 0, rating: 0, link: '' });

  const fetchSurveys = async () => {
    surveys.value = await Api.Bitlabs.v2.getSurveysV2({ sdk: 'IFRAME' }).then(data => {
      const restrictions = data.data.data?.restriction_reason;
      if (restrictions) return [{ uniqueId: '', loi: 0, value: 0, rating: 0, link: '' }];

      const surveys = data.data.data?.surveys;
      if (!surveys) return [{ uniqueId: '', loi: 5, value: 0.69, rating: 4, link: '/qualification' }];

      return surveys.map(survey => ({
        uniqueId: survey.id,
        loi: survey.loi,
        value: parseFloat(survey.cpi),
        rating: Math.min(5, survey.rating),
        link: '/open-survey/' + survey.id,
      }));
    });
  };

  return { surveys, bestSurvey, fetchSurveys };
});

export { useSurveysStore };
