<template>
  <div>
    <transition
      appear
      :enter-from-class="transitionClasses"
      :leave-to-class="transitionClasses"
      enter-active-class="duration-300 ease-out"
      leave-active-class="duration-700 ease-out"
    >
      <div
        v-show="offerwallVisible"
        class="fixed !top-0 z-40 flex h-screen w-screen flex-col items-center justify-center sm:h-[39.375rem] sm:max-h-[calc(100vh_-_25px)] sm:w-[30rem]"
        :class="{
          'sm:bottom-10 sm:left-16': position === 'bottom-left',
          'sm:bottom-10 sm:right-16': position === 'bottom-right',
          'sm:left-16 sm:top-16': position === 'top-left',
          'sm:right-16 sm:top-16': position === 'top-right',
        }"
      >
        <div
          class="absolute right-4 top-4 z-50 flex h-20 w-20 cursor-pointer flex-col items-center justify-center rounded-full bg-white hover:bg-gray-100 dark:bg-black"
          @click="closeOfferwall"
        >
          <font-awesome-icon :icon="faTimes" />
        </div>
        <iframe
          :src="link"
          class="m-0 h-[calc(100vh_-_25px)] w-[calc(100vw_-_30px)] border-0 p-0 shadow-md shadow-black/40 sm:h-[37.5rem] sm:max-h-[calc(100%_-_25px)] sm:w-[28.125rem]"
        />
      </div>
    </transition>

    <best-survey-notification v-if="bestSurveyNotificationVisible" :position="bestSurveyNotificationPosition" />

    <floating-widget
      v-if="floatingWidgetVisible"
      :position="floatingWidgetPosition"
      :message="floatingWidgetCustomMessage"
    />
  </div>
</template>

<script setup lang="ts">
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import { emitter } from '../emitter';
import { useOptionsStore } from '../stores/optionsStore';

import BestSurveyNotification from './BestSurveyNotification.vue';
import FloatingWidget from './FloatingWidget.vue';

const optionsStore = useOptionsStore();
const optionsStoreValues = storeToRefs(optionsStore);

// Offerwall
const offerwallVisible = ref(false);
const link = ref('');
const position = ref('bottom-right');
emitter.on('open-offerwall', event => {
  const fullLink = new URL(`https://web.bitlabs.ai${event.link}`);
  fullLink.searchParams.append('token', optionsStore.token);
  fullLink.searchParams.append('uid', optionsStore.uuid);
  if (link.value !== fullLink.href) link.value = fullLink.href;
  if (event.position !== 'last') position.value = event.position;
  offerwallVisible.value = true;
});
const closeOfferwall = () => {
  offerwallVisible.value = false;
  optionsStore.callback('onOfferwallClose');
};

// Best Survey Notification
const bestSurveyNotificationVisible = computed(() => !optionsStoreValues.disableBestSurveyNotification.value);
const bestSurveyNotificationPosition = optionsStoreValues.bestSurveyNotificationPosition;

// Floating Widget
const floatingWidgetVisible = optionsStoreValues.showFloatingWidget;
const floatingWidgetPosition = optionsStoreValues.floatingWidgetPosition;
const floatingWidgetCustomMessage = optionsStoreValues.customFloatingWidgetMessage;

// Transition
const positionX = computed(() => (position.value.includes('right') ? 'right' : 'left'));
const transitionClasses = computed(() => `opacity-0 ${positionX.value === 'left' ? '-' : ''}translate-x-20`);
</script>
