import { SettingWithValues } from '@bitburst-gmbh/bitlabs-offerwall/src/api/settings';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { Api } from '../api';
import { applyCssVariables } from '../utils/colors';

type Settings = {
  strings: Map<string, string>;
  arrays: Map<string, Array<Record<string, string>>>;
};

const useSettingsStore = defineStore('settings', () => {
  const strings = ref<Settings['strings']>(new Map());
  const arrays = ref<Settings['arrays']>(new Map());

  const settings = computed<Settings>(() => ({
    strings: strings.value,
    arrays: arrays.value,
  }));

  const fetchSettings = async () => {
    const response = await Api.Settings.get();

    for (const setting of response.configuration) {
      if ('value' in setting) {
        strings.value.set(setting.internalIdentifier, setting.value);
      } else if ('values' in setting) {
        arrays.value.set(setting.internalIdentifier, Object.values(transformArrayConfig(setting.values)));
      }
    }

    applyCssVariables(strings.value);

    return Promise.resolve();
  };

  return { fetchSettings, settings };
});

function transformArrayConfig(values: SettingWithValues['values']) {
  return values.reduce<Record<number, Record<string, string>>>((acc: Record<number, Record<string, string>>, value) => {
    const { index, ...rest } = value;
    if (!(acc[index] as undefined | Record<string, string>)) {
      acc[index] = {};
    }
    const key = rest.internalIdentifier.split('.').pop() ?? '';
    acc[index][key] = rest.value;
    return acc;
  }, {});
}

export { useSettingsStore };
