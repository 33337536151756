<template>
  <div
    class="widget-design-notification bg-branding-light-500 dark:bg-branding-dark-500 text-branding-contrast-light-500 dark:text-branding-contrast-dark-500 rounded-16 relative flex items-center justify-center rounded-bl-none px-80 py-12"
  >
    <div
      class="bg-branding-contrast-light-500 dark:bg-branding-contrast-dark-500 absolute -right-8 -top-8 flex h-20 w-20 flex-col items-center justify-center rounded-full shadow-md"
      @click="closeWidget"
    >
      <font-awesome-icon :icon="faTimes" size="lg" class="gradient-icon" />
    </div>
    <div class="text-50 flex w-full items-center justify-center" @click="openOfferwall">
      <font-awesome-icon :icon="faPlusCircle" />
      <div class="ml-24">
        <div class="text-17 flex items-center font-semibold">
          <span v-t="'widgetDesignNotificationEarn'" />
          <currency-amount class="ml-4" :amount="survey.value" />
        </div>
        <div v-t="{ path: 'widgetDesignNotificationTime', args: { loi } }" class="text-13 font-semibold" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { faPlusCircle, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import { Survey } from '../../../types/survey';
import CurrencyAmount from '../../CurrencyAmount.vue';

const props = defineProps<{
  survey: Survey;
}>();

const emit = defineEmits<{
  'close-widget': [];
  'open-offerwall': [];
}>();
const closeWidget = () => emit('close-widget');
const openOfferwall = () => emit('open-offerwall');

const loi = computed(() => (props.survey.loi <= 20 ? props.survey.loi.toString() : '20+'));
</script>
