<template>
  <div class="flex items-center" :class="{ '!inline-flex items-baseline': inline }">
    <span v-if="!hideValue" v-text="prefix" />

    <span
      v-if="!hideAmount"
      :class="{
        'line-through	': crossedOut,
        'mr-4': symbol.isIcon,
      }"
      v-text="displayedAmount"
    />

    <img
      v-if="symbol.isIcon"
      :src="symbol.content"
      class="!max-w-24 h-24 !max-h-24 w-24"
      :class="{
        '!max-w-20 !h-20 !max-h-20 !w-20': iconSize === 'small',
        '!max-w-32 !h-32 !max-h-32 !w-32': iconSize === 'large',
        '!h-[inherit] !w-[unset] self-center': inline,
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  amount: number;
  symbol: { content: string; isIcon: boolean };

  crossedOut?: boolean;
  decimals?: boolean;
  factor?: number;
  hideValue?: boolean;
  iconSize?: 'large' | 'medium' | 'small';
  prefix?: string;
  inline?: boolean;
}>();

const hideAmount = computed(() => props.hideValue && props.symbol.isIcon);

const displayedAmount = computed(() => {
  return props.symbol.isIcon ? formattedAmount.value : wildcardedValue.value;
});
const formattedAmount = computed(() => {
  if (props.hideValue) return '';

  const factor = props.factor ?? 1;
  const factoredValue = props.amount * factor;

  let scale = 1;
  let abbr = '';
  if (factoredValue >= 1e9) {
    scale = 1e9;
    abbr = 'B';
  } else if (factoredValue >= 1e6) {
    scale = 1e6;
    abbr = 'M';
  } else if (factoredValue >= 1e5) {
    // The requirement is to use K when the value is greater than (or equal to) 1e5, not 1e4 or 1e3.
    scale = 1e3;
    abbr = 'K';
  }

  // If the value is not shortened, we probably should consider the decimals.
  if (scale === 1) {
    return props.decimals
      ? factoredValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : Math.floor(factoredValue).toLocaleString();
  }

  const shortenedValue = factoredValue / scale;

  return shortenedValue.toLocaleString(undefined, { maximumFractionDigits: 2 }).concat(abbr);
});
const wildcardedValue = computed(() => {
  const containsWildcard = props.symbol.content.toLowerCase().includes('{value}');
  return containsWildcard
    ? props.symbol.content.replace(/{value}/gi, formattedAmount.value)
    : `${props.symbol.content}${formattedAmount.value}`;
});
</script>
