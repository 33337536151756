import { CacheProvider } from '../cache';

class MemoryCacheProvider implements CacheProvider {
  private data: Record<string, string> = {};

  get(key: string): Promise<string> {
    if (this.data[key]) {
      return Promise.resolve(this.data[key] as string);
    }
    return Promise.reject('Key not found');
  }

  set(key: string, value: string): Promise<void> {
    this.data[key] = value;

    return Promise.resolve();
  }

  del(key: string): Promise<void> {
    if (this.data[key]) {
      delete this.data[key];
      return Promise.resolve();
    }
    return Promise.reject('Key not found');
  }

  keys(): Promise<Array<string>> {
    return Promise.resolve(Object.keys(this.data));
  }

  isAvailable(): Promise<boolean> {
    return Promise.resolve(true);
  }
}

export default MemoryCacheProvider;
