import { HexColorChannels, LinearGradientComponents, VectorCoordinates } from './types';

const hexColorRegex = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
const linearGradientRegex =
  /^linear-gradient\(([\d]+)deg,\s*(#[a-f\d]{6}) (100|[1-9]?[0-9])%,\s*(#[a-f\d]{6}) (100|[1-9]?[0-9])%\)$/i;
const luminanceThreshold = 0.729;

/**
 * Checks if a given string is a valid color hex color
 * @param str - The string that gets checked
 */
export function isHexColor(str: string): boolean {
  return hexColorRegex.test(str);
}

/**
 * Check if a given string is a valid css linear gradient
 * @param str - The string that gets checked
 */
export function isLinearGradient(str: string): boolean {
  return linearGradientRegex.test(str);
}

/**
 * Splits a linear gradient string into it's components and returns them as object
 * @param  gradient - The linear gradient string
 */
export function getLinearGradientComponents(gradient: string): null | LinearGradientComponents {
  const components = linearGradientRegex.exec(gradient);
  if (components === null) return null;

  const degrees = components[1];
  const parsedDegrees = degrees ? (isNaN(parseInt(degrees, 10)) ? undefined : parseInt(degrees, 10)) : undefined;

  const color1 = components[2];

  const offset1 = components[3];
  const parsedOffset1 = offset1 ? (isNaN(parseInt(offset1, 10)) ? undefined : parseInt(offset1, 10)) : undefined;

  const color2 = components[4];
  const offset2 = components[5];

  const parsedOffset2 = offset2 ? (isNaN(parseInt(offset2, 10)) ? undefined : parseInt(offset2, 10)) : undefined;

  return {
    degrees: parsedDegrees,
    color1: color1,
    offset1: parsedOffset1,
    color2: color2,
    offset2: parsedOffset2,
  };
}

/**
 * Splits a hex color into its individual colors and returns them as number
 * @param color - The hex color string
 */
export function getHexColorChannels(color: string): null | HexColorChannels {
  const colors = hexColorRegex.exec(color);
  if (colors === null) return null;

  const red = colors[1];
  const parsedRed = red ? (isNaN(parseInt(red, 16)) ? undefined : parseInt(red, 16)) : undefined;
  const green = colors[2];
  const parsedGreen = green ? (isNaN(parseInt(green, 16)) ? undefined : parseInt(green, 16)) : undefined;
  const blue = colors[3];
  const parsedBlue = blue ? (isNaN(parseInt(blue, 16)) ? undefined : parseInt(blue, 16)) : undefined;

  return {
    red: parsedRed,
    green: parsedGreen,
    blue: parsedBlue,
  };
}

/**
 * Takes a linear gradient and blends all it's colors into one
 * @param gradient - The linear gradient string
 */
export function getBlendedGradientColor(gradient: string): null | string {
  if (isHexColor(gradient)) return gradient;
  if (!isLinearGradient(gradient)) return null;

  const { color1, color2 } = getLinearGradientComponents(gradient)!;
  const channels0 = getHexColorChannels(color1!)!;
  const channels1 = getHexColorChannels(color2!)!;
  const red = (channels0.red! + channels1.red!) / 2;
  const green = (channels0.green! + channels1.green!) / 2;
  const blue = (channels0.blue! + channels1.blue!) / 2;

  return buildHexColor(red, green, blue);
}

/**
 * Calculates the luminance of a given hex color or gradient
 * @param color - The color string that's luminance gets calculated
 */
export function getLuminance(color: string): null | number {
  if (isHexColor(color)) {
    const { red, green, blue } = getHexColorChannels(color)!;

    return (0.299 * red! + 0.587 * green! + 0.114 * blue!) / 255;
  } else if (isLinearGradient(color)) {
    return getLuminance(getBlendedGradientColor(color)!);
  }

  return null;
}

/**
 * Sets the value for the specified css variable on the DOM's root element
 * @param name - The name of the css variable without the dashes
 * @param value - The value of the css variable
 */
export function setCssVariable(name: string, value: string): void {
  document.documentElement.style.setProperty(`--${name}`, value);
}

/**
 * Checks if a given hex color or linear gradient is above a given luminance threshold
 * @param color - The color that gets checked
 * @param threshold - The threshold the color's luminance has to exceed
 */
export function isLuminanceTextThresholdExceeded(color: string, threshold = luminanceThreshold): boolean {
  const luminance = getLuminance(color);
  if (luminance === null) return false;

  return luminance > threshold;
}

/**
 * Builds a Hex color based on three numbers
 * @param red - Red
 * @param green - Green
 * @param blue - Blue
 */
export function buildHexColor(red = 0, green = 0, blue = 0): string {
  const colors = [red, green, blue];

  if (colors.some(color => color < 0 || color > 255)) return '#000000';

  const hexChannels = colors.map(color => Math.round(color).toString(16).padStart(2, '0').toUpperCase());

  return `#${hexChannels[0]}${hexChannels[1]}${hexChannels[2]}`;
}

/**
 * Builds a css linear gradient string from the options provided
 * @param components - The options to build the linear gradient
 */
export function buildLinearGradient(components: LinearGradientComponents = {}): string {
  const defaultComponents = { color1: '#000000', color2: '#000000', degrees: 0, offset1: 0, offset2: 100 };
  const { color1, color2, degrees, offset1, offset2 } = Object.assign(defaultComponents, components);

  return `linear-gradient(${degrees}deg, ${color1.toUpperCase()} ${offset1}%, ${color2.toUpperCase()} ${offset2}%)`;
}

/**
 * Takes a number of degrees and generates coordinates for a normalized direction vector or optionally as percentage
 * @param degrees - The degrees that the vector gets calculated by
 * @param percentage - return the coordinates as percentages
 */
export function convertDegreesToVectorCoordinates(degrees = 0, percentage = false): null | VectorCoordinates {
  // Convert degrees to radians
  const radians = degrees * (Math.PI / 180);

  // Calculate vector coordinates
  // Theoretically x1 and x2 are swapped but this is to be consistent with the css gradient degrees
  // because it rotates clockwise instead of counterclockwise (sin "rotates" counterclockwise)
  const divisor = percentage ? 1 : 100;
  const x1 = Math.round(50 + Math.sin(radians + Math.PI) * 50) / divisor;
  const y1 = Math.round(50 + Math.cos(radians) * 50) / divisor;
  const x2 = Math.round(50 + Math.sin(radians) * 50) / divisor;
  const y2 = Math.round(50 + Math.cos(radians + Math.PI) * 50) / divisor;

  return { x1, y1, x2, y2 };
}

/**
 * Calculates the euclidean distance between two rgb colors and returns it as a percentage
 * @param color1 - The first color that gets compared
 * @param color2 - The second color that gets compared
 */
export function colorDifferenceEuclidean(color1: string, color2: string): number {
  if (!isHexColor(color1) || !isHexColor(color2)) return 0;
  if (color1 === color2) return 0;

  const channels1 = getHexColorChannels(color1)!;
  const channels2 = getHexColorChannels(color2)!;
  const squaredDeltaRed = Math.pow(channels1.red! - channels2.red!, 2);
  const squaredDeltaGreen = Math.pow(channels1.green! - channels2.green!, 2);
  const squaredDeltaBlue = Math.pow(channels1.blue! - channels2.blue!, 2);
  const squaredDeltaMax = Math.pow(255, 2);
  const euclideanDistance = Math.sqrt(squaredDeltaRed + squaredDeltaGreen + squaredDeltaBlue);
  const euclideanDistanceMax = Math.sqrt(squaredDeltaMax + squaredDeltaMax + squaredDeltaMax);

  return (euclideanDistance / euclideanDistanceMax) * 100;
}

/**
 * Inverts a hex color string
 * @param color - The color that gets inverted
 */
export function invertHexColor(color: string): string {
  if (!isHexColor(color)) return color;

  //the 1 is prefixed so the bitwise XOR can overflow
  const invertedColor = Number(`0x1${color.substring(1)}`) ^ 0xffffff;
  return `#${invertedColor.toString(16).substring(1).toUpperCase()}`;
}

/**
 * Returns the gray equivalent to a given color
 * @param color - The color that gets used to calculate the grayscale color
 */
export function grayscaleHexColor(color: string): string {
  if (!isHexColor(color)) return color;

  const luminance = getLuminance(color)!;
  const grayLevel = luminance * 255;

  return buildHexColor(grayLevel, grayLevel, grayLevel);
}

/**
 * Lightens a color by a defined percentage amount
 * @param color - The color that gets lightened
 * @param amount - The percentage the color gets lightened
 */
export function lightenHexColor(color: string, amount = 10): string {
  if (!isHexColor(color)) return color;

  amount /= 100;
  let { red, green, blue } = getHexColorChannels(color)!;
  red = Math.min(255, red! + 255 * amount);
  green = Math.min(255, green! + 255 * amount);
  blue = Math.min(255, blue! + 255 * amount);

  return buildHexColor(red, green, blue);
}

/**
 * Darkens a color by a defined percentage amount
 * @param color - The color that gets darkened
 * @param amount - The percentage the color gets darkened
 */
export function darkenHexColor(color: string, amount = 10): string {
  if (!isHexColor(color)) return color;

  amount /= 100;
  let { red, green, blue } = getHexColorChannels(color)!;
  red = Math.max(0, red! - 255 * amount);
  green = Math.max(0, green! - 255 * amount);
  blue = Math.max(0, blue! - 255 * amount);

  return buildHexColor(red, green, blue);
}
