import { defineStore } from 'pinia';
import { ref } from 'vue';

import { Api } from '../api';
import { Offer } from '../types/offer';

const useOffersStore = defineStore('offers', () => {
  const offers = ref<Array<Offer>>([]);
  const offerwallCode = ref<string>('');

  const fetchOffers = async () => {
    offers.value = await Api.Bitlabs.v2.getOffersV2().then(data => {
      const offers = data.data.data?.offers;
      offerwallCode.value = data.data.data?.offerwall_code ?? '';
      if (!offers) return [];

      return offers.map(offer => ({
        id: offer.id,
        title: offer.anchor,
        icon: offer.icon_url,
        cpa: parseFloat(offer.total_points),
        link: `/offers?offer-id=${offer.id}`,
      }));
    });
  };

  return { offers, offerwallCode, fetchOffers };
});

export { useOffersStore };
