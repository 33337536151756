import axios from 'axios';

import { useOptionsStore } from '../stores/optionsStore';

export type SettingWithValues = { internalIdentifier: string; values: Array<SettingWithValue & { index: number }> };
type SettingWithValue = { internalIdentifier: string; value: string };
type Setting = SettingWithValue | SettingWithValues;
type Settings = {
  configuration: Array<Setting>;
  publisher: {
    isVerified: boolean;
  };
};

export default {
  async get() {
    const { token } = useOptionsStore();
    const response = await axios.get<Settings>(`https://dashboard.bitlabs.ai/api/public/v1/apps/${token}`);
    return response.data;
  },
};
