import mitt from 'mitt';

import type { WidgetPosition } from './types/widget';

type Events = {
  'open-offerwall': { position: WidgetPosition | 'last'; link: string };
};

const emitter = mitt<Events>();

export { emitter };
