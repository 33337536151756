import { isLuminanceTextThresholdExceeded, setCssVariable } from '@bitburst-gmbh/bitburst-colors';

const FONT_COLOR_DARK = '#111B1F';
const FONT_COLOR_LIGHT = '#FFFFFF';

function applyCssVariables(configStrings: Map<string, string>) {
  const brandingLight = configStrings.get('app.visual.light.survey_icon_color') ?? '#FFFFFF';
  const brandingContrastLight = isLuminanceTextThresholdExceeded(brandingLight) ? FONT_COLOR_DARK : FONT_COLOR_LIGHT;
  setCssVariable(`offerwall-branding-light-500`, brandingLight);
  setCssVariable(`offerwall-branding-contrast-light-500`, brandingContrastLight);

  const brandingDark = configStrings.get('app.visual.dark.survey_icon_color') ?? '#000000';
  const brandingContrastDark = isLuminanceTextThresholdExceeded(brandingDark) ? FONT_COLOR_DARK : FONT_COLOR_LIGHT;
  setCssVariable(`offerwall-branding-dark-500`, brandingDark);
  setCssVariable(`offerwall-branding-contrast-dark-500`, brandingContrastDark);
}

export { applyCssVariables };
