/* eslint-disable import/no-unresolved */
//@ts-expect-error This virtual module will not be needed in the future anymore
import { loadLanguage } from 'virtual:poeditor';
import { createI18n } from 'vue-i18n';

const supportedLanguages = ['en'];
const loadedLanguages: Array<string> = [];
const userLanguage = window.navigator.language || 'en';

const i18n = createI18n({
  legacy: false,
  locale: userLanguage.slice(0, 2),
  fallbackLocale: 'en',
  silentFallbackWarn: process.env.NODE_ENV !== 'development',
});

async function loadLanguageAsync(lang: string) {
  if (!supportedLanguages.includes(lang)) return Promise.reject();
  if (loadedLanguages.includes(lang)) return Promise.resolve(lang);

  return Promise.all([loadLanguage('bitlabsWidgets', lang)]).then(([messagesOfferwall]) => {
    i18n.global.mergeLocaleMessage(lang, messagesOfferwall);
    loadedLanguages.push(lang);
    return lang;
  });
}

export { i18n, loadLanguageAsync };
