<template>
  <div
    class="widget-design-offer rounded-8 flex h-[9.25rem] w-[9.25rem] flex-col items-center justify-between shadow-[inset_0_-4.25rem_3.75rem_-1.75rem_#000000]"
    :style="style"
    :data-ow-sdk-offer-id="offer.id"
    @click="openOfferwall"
  >
    <div class="flex w-full items-center">
      <div
        class="rounded-4 bg-branding-light-500 dark:bg-branding-dark-500 text-branding-contrast-light-500 dark:text-branding-contrast-dark-500 ml-4 mt-4 px-10"
      >
        <currency-amount class="text-17 font-semibold" :amount="offer.cpa" icon-size="small" />
      </div>
    </div>
    <div class="text-11 mb-10 text-center font-semibold text-white">
      {{ offer.title }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { Offer } from '../../../types/offer';
import CurrencyAmount from '../../CurrencyAmount.vue';

const props = defineProps<{
  offer: Offer;
}>();

const emit = defineEmits<{
  'open-offerwall': [];
}>();
const openOfferwall = () => emit('open-offerwall');

const style = computed(() => `background: url("${props.offer.icon}"); background-size: cover;`);
</script>
