<template>
  <div class="widget-design-leaderboard-next-reset mt-4 w-full">
    <div class="text-13 flex w-full items-center justify-between font-semibold">
      <div v-t="'widgetDesignLeaderboardNextResetLabel'" class="font-regular" />
      <div v-text="nextResetText" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useIntervalFn } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  nextReset: Date;
}>();

const { t } = useI18n();

const msDiff = ref(0);
const nextResetText = computed(() => {
  const hourDiff = msDiff.value / (60 * 60 * 1000);

  if (msDiff.value <= 0) {
    return t('widgetDesignLeaderboardNextResetNow');
  } else if (hourDiff < 24) {
    const seconds = Math.floor(msDiff.value / 1000) % 60;
    const minutes = Math.floor(msDiff.value / 1000 / 60) % 60;
    const hours = Math.floor(msDiff.value / 1000 / 60 / 60) % 24;
    const format = (time: number) => time.toString().padStart(2, '0');
    return `${format(hours)}:${format(minutes)}:${format(seconds)}`;
  } else {
    return props.nextReset.toLocaleTimeString(undefined, {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  }
});
const calcMsDiff = () => {
  msDiff.value = props.nextReset.getTime() - new Date().getTime();
};
useIntervalFn(calcMsDiff, 1000);
</script>
