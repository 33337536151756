<template>
  <div class="widget-design-leaderboard-user-rankings">
    <div class="flex items-center justify-between bg-gray-50 p-4 pr-10 dark:bg-gray-800">
      <div class="flex items-center">
        <font-awesome-icon
          :icon="faTrophy"
          class="mr-4"
          :class="{
            'text-yellow-500': props.minRank === 1,
            'text-gray-500': props.minRank === 2,
            'text-[#c07048]': props.minRank === 3,
            'text-branding-light-500 dark:text-branding-dark-500': props.minRank > 3,
          }"
        />
        <div
          class="text-13 bg-branding-light-500 dark:bg-branding-dark-500 !bg-clip-text font-semibold text-transparent"
          v-text="rankRange"
        />
      </div>
      <currency-amount
        v-if="reward"
        :amount="reward"
        icon-size="small"
        class="text-13 !bg-clip-text font-semibold text-transparent"
      />
    </div>
    <widget-design-leaderboard-user
      v-for="user in users"
      :key="user.rank"
      :name="user.name"
      :rank="user.rank"
      :earnings="user.earnings"
      :is-own-user="user.rank === ownUserRank"
    />
  </div>
</template>

<script lang="ts" setup>
import { faTrophy } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { WidgetLeaderboardUser } from '../../../types/leaderboard';
import CurrencyAmount from '../../CurrencyAmount.vue';

import WidgetDesignLeaderboardUser from './WidgetDesignLeaderboardUser.vue';

const props = defineProps<{
  minRank: number;
  maxRank: number;
  reward: number;
  users: Array<WidgetLeaderboardUser>;
  ownUserRank: number;
}>();

const { t } = useI18n();

const rankRange = computed(() => {
  if (props.minRank === props.maxRank) return t('widgetDesignLeaderboardUserRankings', { ranks: props.minRank });
  return t('widgetDesignLeaderboardUserRankings', { ranks: `${props.minRank} - ${props.maxRank}` });
});
</script>
