<template>
  <div
    class="widget-design-offer-show-more-tile rounded-8 flex h-[9.25rem] w-[9.25rem] flex-col items-center justify-center"
    :style="style"
    @click="openOfferwall"
  >
    <div v-if="icon" class="flex items-center px-40 text-white">
      <div v-t="'showMoreOfferTileText'" />
      <font-awesome-icon :icon="faAngleRight" class="ml-16" size="xl" />
    </div>
    <div v-else v-t="'showMoreOfferTileNoOffersText'" />
  </div>
</template>

<script lang="ts" setup>
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

const props = defineProps<{
  icon?: string;
}>();

const emit = defineEmits<{
  'open-offerwall': [];
}>();
const openOfferwall = () => emit('open-offerwall');

const style = computed(() => {
  return `background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.44) 0%, #111B1F 100%), url("${props.icon}"); background-size: cover;`;
});
</script>
