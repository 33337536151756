<template>
  <div
    class="widget-design-compact bg-branding-light-500 dark:bg-branding-dark-500 text-branding-contrast-light-500 dark:text-branding-contrast-dark-500 rounded-8 flex items-center justify-between overflow-hidden p-12"
    @click="openOfferwall"
  >
    <div class="text-11 flex flex-col justify-center font-semibold">
      <div class="flex items-center">
        <font-awesome-icon :icon="faClock" class="mr-4" />
        <span v-t="{ path: 'widgetDesignCompactTime', args: { loi } }" />
      </div>
      <div class="mt-10 flex items-center">
        <survey-rating :rating="survey.rating" />
        <div class="ml-4" v-text="survey.rating" />
      </div>
    </div>
    <bb-button
      class="hover:bg-branding-contrast-light-500 dark:hover:bg-branding-contrast-dark-500 text-28 bg-branding-contrast-light-500 dark:bg-branding-contrast-dark-500 ml-40 flex items-center px-10 py-4"
    >
      <font-awesome-icon :icon="faPlayCircle" class="gradient-icon" />
      <div
        class="text-13 bg-branding-light-500 dark:bg-branding-dark-500 ml-16 flex flex-col justify-center !bg-clip-text font-semibold text-transparent"
      >
        <span v-t="'widgetDesignCompactEarn'" />
        <currency-amount :amount="survey.value" icon-size="small" />
      </div>
    </bb-button>
  </div>
</template>

<script setup lang="ts">
import { BbButton } from '@bitburst-gmbh/bitburst-ui';
import { faClock, faPlayCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import { Survey } from '../../../types/survey';
import CurrencyAmount from '../../CurrencyAmount.vue';
import SurveyRating from '../../SurveyRating.vue';

const props = defineProps<{
  survey: Survey;
}>();

const emit = defineEmits<{
  'open-offerwall': [];
}>();
const openOfferwall = () => emit('open-offerwall');

const loi = computed(() => (props.survey.loi <= 20 ? props.survey.loi.toString() : '20+'));
</script>
