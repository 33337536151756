import { defineStore } from 'pinia';
import { ref } from 'vue';

import { WidgetPosition } from '../types/widget';

const defaultCallback = (event: string) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(event);
  }
};

const useOptionsStore = defineStore('options', () => {
  const bestSurveyNotificationPosition = ref<WidgetPosition>('bottom-left');
  const callback = ref(defaultCallback);
  const customFloatingWidgetMessage = ref('');
  const disableBestSurveyNotification = ref(false);
  const floatingWidgetPosition = ref('bottom-right');
  const showFloatingWidget = ref(false);
  const token = ref('');
  const username = ref('');
  const uuid = ref('');

  return {
    bestSurveyNotificationPosition,
    callback,
    customFloatingWidgetMessage,
    disableBestSurveyNotification,
    floatingWidgetPosition,
    showFloatingWidget,
    token,
    username,
    uuid,
  };
});

export { defaultCallback, useOptionsStore };
