import { Api as BitlabsApi } from '@prodege-prod/bitlabs-api';

import SettingsRequests from './settings';

const BitlabsRequests = new BitlabsApi();

const Api = {
  Bitlabs: BitlabsRequests,
  Settings: SettingsRequests,
};

const initApi = (token: string, uuid: string) => {
  BitlabsRequests.instance.defaults.headers['X-Api-Token'] = token;
  BitlabsRequests.instance.defaults.headers['X-User-Id'] = uuid;
};

export { Api, initApi };
