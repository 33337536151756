import { storeToRefs } from 'pinia';

import { useOptionsStore } from './stores/optionsStore';
import { useSurveysStore } from './stores/surveysStore';
import type { SdkOptions, WidgetDesign, WidgetOptions, WidgetPosition, WidgetProps } from './types/widget';

type UpdateSdkOptions = Partial<SdkOptions & { uuid: string }>;
type UpdateWidgetOptions = Partial<WidgetOptions & { design: WidgetDesign; position: WidgetPosition }>;

const interfaceSdk = () => ({
  updateOptions(options?: UpdateSdkOptions) {
    const storeValues = storeToRefs(useOptionsStore());
    if (options?.username) storeValues.username.value = options.username;
    if (options?.uuid) storeValues.uuid.value = options.uuid;
    if (options?.disableBestSurveyNotification !== undefined)
      storeValues.disableBestSurveyNotification.value = options.disableBestSurveyNotification;
    if (options?.bestSurveyNotificationPosition)
      storeValues.bestSurveyNotificationPosition.value = options.bestSurveyNotificationPosition;
    if (options?.showFloatingWidget !== undefined) storeValues.showFloatingWidget.value = options.showFloatingWidget;
    if (options?.floatingWidgetPosition) storeValues.floatingWidgetPosition.value = options.floatingWidgetPosition;
    if (options?.customFloatingWidgetMessage)
      storeValues.customFloatingWidgetMessage.value = options.customFloatingWidgetMessage;
  },
});

const interfaceWidget = (props: WidgetProps) => ({
  /**
   * Update the Instance Props
   */
  updateOptions(options?: UpdateWidgetOptions) {
    if (options?.design) props.design = options.design;
    if (options?.position) props.position = options.position;
    if (options?.onClick) props.options.onClick = options.onClick;
    if (options?.rows) props.options.rows = options.rows;
    if (options?.autoWidth) props.options.autoWidth = options.autoWidth;
    if (options?.noFakeSurveys) props.options.noFakeSurveys = options.noFakeSurveys;

    // to refresh the surveys faster
    const surveysStore = useSurveysStore();
    void surveysStore.fetchSurveys();
  },
});

export { interfaceSdk, interfaceWidget };
