import hmacSHA256 from 'crypto-js/hmac-sha256';
import EventEmitter from 'events';
import { Base64 } from 'js-base64';
import MemoryCache from './providers/memory';

type CacheItem = { type: string; value: unknown; expires: number };

export interface CacheProvider {
  get(key: string): Promise<string | undefined>;
  set(key: string, value: string): Promise<void>;
  del(key: string): Promise<void>;

  keys(): Promise<Array<string>>;

  isAvailable(): Promise<boolean>;
}

type CacheOptions = {
  cacheName: string;
  cleanupInterval: number;
  hashSalt: string | null;
  provider: CacheProvider;
};

type DefaultMeta = {
  [key: string]: unknown;
};

class BitburstCache<CacheMeta = DefaultMeta> extends EventEmitter {
  /** The options for this cache instance */
  public options: CacheOptions = {
    cacheName: 'default',
    cleanupInterval: 600, // 10min
    hashSalt: null,
    provider: new MemoryCache(),
  };

  /** The id of the cleanup interval */
  public cleanupIntervalId?: ReturnType<typeof setInterval>;

  /** @constructor to set the caches options, load persistent saved data and start the cleanup timer */
  constructor(options: Partial<CacheOptions> = {}) {
    super();

    this.options = Object.assign(this.options, options);
    // Just schedule the cleanup, no need to wait, because all operations check if the data is valid
    this._cleanup();
    this.cleanupIntervalId = setInterval(this._cleanup.bind(this), this.options.cleanupInterval * 1000);
  }

  /** @public function to save a value to the cache */
  public set<S extends keyof CacheMeta>(key: S, value: CacheMeta[S], ttl = 0): Promise<void> {
    if (typeof key !== 'string') return Promise.reject('Key must be a string');
    const storageKey = this._getStorageKey(key);

    const data: CacheItem = {
      type: typeof value,
      value: value,
      expires: this._getExpiryTime(ttl),
    };

    this.emit('set', key, data);

    return this._setData(storageKey, data);
  }

  /** @public function to get a value from the cache */
  public async get<S extends keyof CacheMeta>(key: S): Promise<CacheMeta[S]> {
    if (typeof key !== 'string') return Promise.reject('Key must be a string');
    const storageKey = this._getStorageKey(key);
    const data = await this._getData(storageKey);
    if (!data) return Promise.reject('Data not valid');

    return data.value as CacheMeta[S];
  }

  /** @public function to delete a value from the cache */
  public async del(key: keyof CacheMeta): Promise<void> {
    if (typeof key !== 'string') return Promise.reject('Key must be a string');
    const storageKey = this._getStorageKey(key);
    if (!(await this._keys()).includes(storageKey)) return Promise.reject("Key doesn't exist");
    this.emit('del', key);
    return this.options.provider.del(storageKey);
  }

  /** @public function to get the keys of the current cache instance */
  public keys(): Promise<Array<keyof CacheMeta>> {
    return this._keys().then(keys => keys.map(key => key.slice(this.options.cacheName.length + 1) as keyof CacheMeta));
  }

  /** @public function to check if the cache has a specific value */
  public async has(key: keyof CacheMeta): Promise<boolean> {
    if (typeof key !== 'string') return Promise.reject('Key must be a string');
    const storageKey = this._getStorageKey(key);
    return (await this._keys()).includes(storageKey) && !!(await this._getData(storageKey));
  }

  /** @public function to flush the current cache */
  public async flush(): Promise<void> {
    // Uses the public del interface, so we don't need the internal key
    const keys = await this.keys();
    for (const key of keys) await this.del(key);
    this.emit('flush');
  }

  /** @public function to get or set the ttl for a value */
  public async ttl(key: keyof CacheMeta, ttl?: number): Promise<number> {
    if (typeof key !== 'string') return Promise.reject('Key must be a string');
    const storageKey = this._getStorageKey(key);
    if (!(await this._keys()).includes(storageKey)) return Promise.reject("Key doesn't exist");

    const data = await this._getData(storageKey);
    if (!data) return Promise.reject('Data not valid');

    if (typeof ttl === 'number' && ttl >= 0) {
      // Set ttl
      const expiresAt = this._getExpiryTime(ttl);
      data.expires = expiresAt;
      await this._setData(storageKey, data);
      return expiresAt;
    }

    // Return ttl
    return data.expires;
  }

  /** @public function that clears the cleanup interval */
  public clearInterval(): boolean {
    if (this.cleanupIntervalId) {
      clearInterval(this.cleanupIntervalId);
      this.cleanupIntervalId = undefined;

      return true;
    }
    return false;
  }

  private _keys(): Promise<Array<string>> {
    return this.options.provider.keys().then(keys => keys.filter(key => key.startsWith(this.options.cacheName)));
  }

  /** @private function that returns the actual storage key */
  private _getStorageKey(key: string): string {
    return this.options.cacheName + '.' + key;
  }

  /** @private function that calculates the expiry time based on a ttl (in seconds) added to the current time */
  private _getExpiryTime(ttl: number): number {
    if (typeof ttl === 'number' && ttl > 0) {
      return new Date().getTime() + ttl * 1000;
    }
    return 0;
  }

  /** @private function to check if a value is expired and deletes it when it is expired */
  private async _checkExpiryTime(key: string, data: CacheItem): Promise<boolean> {
    const retVal = new Date().getTime() < data.expires || data.expires === 0;

    if (!retVal) {
      await this.options.provider.del(key);
      this.emit('expired', key);
    }

    return retVal;
  }

  /** @private function to set data */
  private async _setData(key: string, data: CacheItem): Promise<void> {
    const raw = this._encode(data);
    return this.options.provider.set(key, raw);
  }

  /** @private function to check if the data is valid and not expired, returns the data if valid */
  private async _getData(key: string): Promise<CacheItem | null> {
    if (!(await this._keys()).includes(key)) return Promise.reject("Key doesn't exist");

    const data = this._decode((await this.options.provider.get(key)) || '');
    if (!data || !(await this._checkExpiryTime(key, data))) return null;

    return data;
  }

  /** @private function to keep the cache consistent. Deletes all values that are expired */
  private async _cleanup(): Promise<void> {
    for (const key of await this._keys()) {
      await this._getData(key);
    }
  }

  /** @private function that indicates if the cache is hashed or not */
  private _isHashed(): boolean {
    return this.options.hashSalt !== null;
  }

  /** @private function to encode data */
  private _encode(item: CacheItem): string {
    const data = JSON.stringify(item);
    if (!this._isHashed()) return data;

    const b64Encoded = Base64.encode(data);

    return b64Encoded + '.' + hmacSHA256(b64Encoded, this.options.hashSalt as string).toString();
  }

  /** @private function to decode data */
  private _decode(data: string): CacheItem | undefined {
    const checkDataStructure = (obj: unknown): obj is CacheItem => {
      if (!obj || typeof obj !== 'object') return false;
      if (!Object.prototype.hasOwnProperty.call(obj, 'type') || !Object.prototype.hasOwnProperty.call(obj, 'expires'))
        return false;

      // Undefined check
      if ((obj as { type: string }).type === 'undefined') return true;

      return Object.prototype.hasOwnProperty.call(obj, 'value');
    };

    let extractedPayload = data;

    if (this._isHashed()) {
      const [payload, signature] = data.split('.');

      // return if data is tainted
      if (hmacSHA256(payload ?? '', this.options.hashSalt as string).toString() !== signature) return undefined;
      extractedPayload = Base64.decode(payload ?? '');
    }

    const obj = JSON.parse(extractedPayload);
    if (!checkDataStructure(obj)) return undefined;

    // NaN check
    if (obj.type === 'number' && obj.value === null) return { type: obj.type, value: NaN, expires: obj.expires };

    return obj;
  }
}

export default BitburstCache;
