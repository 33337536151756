<template>
  <div
    class="widget-design-full-width bg-branding-light-500 dark:bg-branding-dark-500 text-branding-contrast-light-500 dark:text-branding-contrast-dark-500 rounded-8 flex w-full items-center justify-between px-16 py-12"
    @click="openOfferwall"
  >
    <div class="flex items-center justify-center">
      <survey-rating :rating="survey.rating" />
      <div class="ml-4" v-text="survey.rating" />
      <div class="text-15 ml-24 flex items-center justify-center">
        <font-awesome-icon :icon="faClock" class="mr-4" />
        <span v-t="{ path: 'widgetDesignFullWidthTime', args: { loi } }" />
      </div>
      <currency-amount class="text-15 ml-24" :amount="survey.value" />
    </div>
    <bb-button
      class="bg-branding-contrast-light-500 dark:bg-branding-contrast-dark-500 hover:bg-branding-contrast-light-500 dark:hover:bg-branding-contrast-dark-500 ml-80 flex flex-col items-center justify-center px-32 py-4"
    >
      <span
        v-t="'widgetDesignFullWidthEarn'"
        class="bg-branding-light-500 dark:bg-branding-dark-500 !bg-clip-text text-transparent"
      />
    </bb-button>
  </div>
</template>

<script lang="ts" setup>
import { BbButton } from '@bitburst-gmbh/bitburst-ui';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import { Survey } from '../../../types/survey';
import CurrencyAmount from '../../CurrencyAmount.vue';
import SurveyRating from '../../SurveyRating.vue';

const props = defineProps<{
  survey: Survey;
}>();

const emit = defineEmits<{
  'open-offerwall': [];
}>();
const openOfferwall = () => emit('open-offerwall');

const loi = computed(() => (props.survey.loi <= 20 ? props.survey.loi.toString() : '20+'));
</script>
