import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { Api } from '../api';
import type { WidgetLeaderboard, WidgetLeaderboardRewards } from '../types/leaderboard';

const useLeaderboardStore = defineStore('leaderboard', () => {
  const leaderboard = ref<null | WidgetLeaderboard>(null);

  const ownRank = computed(() => leaderboard.value?.ownUser?.rank ?? 0);

  const rewardFallback = {
    1: {
      minRank: 1,
      maxRank: 100,
      reward: 0,
    },
  };
  const rewards = ref<WidgetLeaderboardRewards>(rewardFallback);

  const fetchLeaderboard = async () => {
    const response = await Api.Bitlabs.v1.getLeaderboard();
    const data = response.data.data;
    if (!data) return null;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (data.rewards === null || data.rewards.length === 0) return null;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const topUsers = data.top_users ?? [];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const rankedRewards = (data.rewards ?? []).reduce((akk, reward) => {
      akk.set(reward.rank, reward.reward_raw);
      return akk;
    }, new Map<number, number>());

    const mappedRewards = data.rewards.reduce((akk, reward) => {
      if (akk.has(reward.reward_raw)) akk.get(reward.reward_raw)?.push(reward.rank);
      else akk.set(reward.reward_raw, [reward.rank]);
      return akk;
    }, new Map<number, Array<number>>());

    const rewardsMap = new Map<number, { minRank: number; maxRank: number; reward: number }>();

    mappedRewards.forEach((ranks, reward) => {
      const minRank = Math.min(...ranks);
      const maxRank = Math.max(...ranks);
      rewardsMap.set(minRank, { minRank, maxRank, reward });
    });

    const ownUser = data.own_user
      ? {
          rank: data.own_user.rank,
          name: data.own_user.name,
          earnings: data.own_user.earnings_raw,
          reward: data.rewards.find(reward => reward.rank === data.own_user?.rank)?.reward_raw ?? 0,
        }
      : null;

    leaderboard.value = {
      nextResetAt: new Date(data.next_reset_at ?? ''),
      rewards: Object.fromEntries(rewardsMap),
      topUsers: topUsers.map(user => ({
        rank: user.rank,
        name: user.name,
        earnings: user.earnings_raw,
        reward: rankedRewards.get(user.rank) ?? 0,
      })),
      ownUser,
    };

    // Rewards
    if (!leaderboard.value.rewards) {
      rewards.value[1] = {
        minRank: 1,
        maxRank: 100,
        reward: 0,
      };
      return;
    }

    const rewardsKeys = Object.keys(leaderboard.value.rewards);
    const lastRewardKey = rewardsKeys[rewardsKeys.length - 1];
    if (!lastRewardKey) return;
    const maxRewardedUser = leaderboard.value.rewards[lastRewardKey].maxRank;
    rewards.value = leaderboard.value.rewards;

    rewards.value[maxRewardedUser + 1] = {
      minRank: maxRewardedUser + 1,
      maxRank: 100,
      reward: 0,
    };
  };

  return { leaderboard, ownRank, rewards, fetchLeaderboard };
});

export { useLeaderboardStore };
