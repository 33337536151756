<template>
  <div
    class="widget-design-simple rounded-4 text-50 bg-branding-light-500 dark:bg-branding-dark-500 text-branding-contrast-light-500 dark:text-branding-contrast-dark-500 flex items-center justify-center p-40"
    @click="openOfferwall"
  >
    <font-awesome-icon :icon="faPlayCircle" />
    <div class="ml-24">
      <div class="text-17 flex items-center font-semibold">
        <span v-t="'widgetDesignSimpleEarn'" />
        <currency-amount :amount="survey.value" class="ml-4" />
      </div>
      <div v-t="{ path: 'widgetDesignSimpleTime', args: { loi } }" class="text-13 font-semibold" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { faPlayCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import { Survey } from '../../../types/survey';
import CurrencyAmount from '../../CurrencyAmount.vue';

const props = defineProps<{
  survey: Survey;
}>();

const emit = defineEmits<{
  'open-offerwall': [];
}>();
const openOfferwall = () => emit('open-offerwall');

const loi = computed(() => (props.survey.loi <= 20 ? props.survey.loi.toString() : '20+'));
</script>
