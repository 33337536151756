import { storeToRefs } from 'pinia';

import { useSurveysStore } from '../stores/surveysStore';
import { Survey } from '../types/survey';

function generateFakeSurveys(amount: number): Array<Survey> {
  const { surveys } = storeToRefs(useSurveysStore());
  const fakeSurveys: Array<Survey> = [];
  for (let i = 0; i < amount; i++) {
    if (!surveys.value[0]) return [];

    fakeSurveys.push({
      uniqueId: 'fake',
      loi: getRandomInt(1, 9),
      value: getRandomFloat(0.5, 3),
      rating: getRandomInt(3, 5),
      link: surveys.value[0].link === '/qualification' ? '/qualification' : '/',
    });
  }
  return fakeSurveys;
}

function getRandomInt(min: number, max: number) {
  return Math.floor(getRandomFloat(min, max));
}

function getRandomFloat(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export { generateFakeSurveys };
