<template>
  <bb-currency-amount
    :amount="amount"
    :symbol="currencySymbol"
    :icon-size="iconSize"
    :hide-value="hideValue"
    :decimals="currencyDecimals"
    :factor="currencyFactor"
  />
</template>

<script setup lang="ts">
import { BbCurrencyAmount } from '@bitburst-gmbh/bitburst-ui';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useSettingsStore } from '../stores/settingsStore';

defineProps<{
  amount: number;
  iconSize?: 'large' | 'medium' | 'small';
}>();

const { settings } = storeToRefs(useSettingsStore());
const currencyDecimals = computed<boolean>(() => settings.value.strings.get('general.currency.floor_decimal') !== '1');
const currencyFactor = computed<number>(() => Number(settings.value.strings.get('general.currency.factor') ?? 0));
const currencySymbol = computed<{ isIcon: boolean; content: string }>(() => ({
  isIcon: settings.value.strings.get('general.currency.symbol.is_image') === '1',
  content: settings.value.strings.get('general.currency.symbol.content') ?? '',
}));
const hideValue = computed<boolean>(() => settings.value.strings.get('app.visual.hide_reward_value') === '1');
</script>
