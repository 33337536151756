// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { Api } from '../api';

const useEarningsStore = defineStore('earnings', () => {
  const today = ref(0);
  const total = ref(0);

  const fetchEarnings = async () => {
    const data = await Api.Bitlabs.v1.getUserHistory().then(response => {
      const data = response.data.data;
      if (!data) return { today: 0, total: 0 };

      const rewardedHistory = data.filter(
        historyItem => (historyItem.type === 'COMPLETE' || historyItem.type === 'SCREENOUT') && !historyItem.reconciled
      );
      const today = new Date().setHours(0, 0, 0, 0);
      const todayHistory = rewardedHistory.filter(
        historyItem => today === new Date(historyItem.created_at).setHours(0, 0, 0, 0)
      );

      return {
        today: todayHistory.reduce((a, b) => a + Number(b.user_value), 0),
        total: rewardedHistory.reduce((a, b) => a + Number(b.user_value), 0),
      };
    });

    today.value = data.today;
    total.value = data.total;
  };

  return { today, total, fetchEarnings };
});

export { useEarningsStore };
