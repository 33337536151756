<template>
  <div
    class="floating-widget bg-branding-light-500 dark:bg-branding-dark-500 group fixed flex h-64 w-64 cursor-pointer items-center justify-center rounded-full"
    :class="{
      'animate-shake': playAnimation,
      'bottom-16 left-16': position === 'bottom-left',
      'bottom-16 right-16': position === 'bottom-right',
      'left-16 top-16': position === 'top-left',
      'right-16 top-16': position === 'top-right',
    }"
    @click="openOfferwall"
  >
    <svg-survey-icon />
    <div
      class="rounded-4 pointer-events-none invisible absolute whitespace-nowrap border border-gray-100 bg-gray-50 px-10 py-4 shadow-lg group-hover:visible dark:border-gray-800 dark:bg-gray-800"
      :class="{
        'left-[4.25rem]': positionX === 'left',
        'right-[4.25rem]': positionX === 'right',
      }"
      v-text="message"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

import { emitter } from '../emitter';

import SvgSurveyIcon from './svgs/SvgSurveyIcon.vue';

const props = defineProps<{
  message: string;
  position: string;
}>();

const playAnimation = ref(true);

const positionX = computed(() => (props.position.includes('right') ? 'right' : 'left'));

const openOfferwall = () => {
  if (playAnimation.value) playAnimation.value = false;
  emitter.emit('open-offerwall', { position: 'last', link: '' });
};
</script>
