<template>
  <div
    class="widget-design-leaderboard box-border flex h-full max-h-full w-full items-center justify-center p-10"
    @click="openOfferwall"
  >
    <bb-spinner v-if="!leaderboard" class="my-32" />
    <div v-else class="flex h-full w-full flex-col">
      <div class="flex items-center">
        <div v-t="'widgetDesignLeaderboardTitle'" class="text-17 mr-4 font-semibold" />
      </div>

      <widget-design-leaderboard-next-reset :next-reset="leaderboard.nextResetAt" />
      <widget-design-leaderboard-user-rank :rank="ownRank" />

      <div class="mt-15 w-full overflow-auto">
        <widget-design-leaderboard-user-rankings
          v-for="(reward, index) in rewards"
          :key="index"
          :min-rank="reward.minRank"
          :max-rank="reward.maxRank"
          :reward="reward.reward"
          :users="leaderboard.topUsers.slice(reward.minRank - 1, reward.maxRank)"
          :own-user-rank="ownRank"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BbSpinner } from '@bitburst-gmbh/bitburst-ui';
import { storeToRefs } from 'pinia';

import { useLeaderboardStore } from '../../../stores/leaderboardStore';

import WidgetDesignLeaderboardNextReset from './WidgetDesignLeaderboardNextReset.vue';
import WidgetDesignLeaderboardUserRank from './WidgetDesignLeaderboardUserRank.vue';
import WidgetDesignLeaderboardUserRankings from './WidgetDesignLeaderboardUserRankings.vue';

const emit = defineEmits<{
  'open-offerwall': [];
}>();
const openOfferwall = () => emit('open-offerwall');

const leaderboardStore = useLeaderboardStore();
const { leaderboard, ownRank, rewards } = storeToRefs(leaderboardStore);
void leaderboardStore.fetchLeaderboard();
</script>
