import { Cache, WebCacheProvider } from '@bitburst-gmbh/cache';

type CacheMeta = {
  sessionDate: string;
  sessionIDs: Array<string>;
};

export const cache = new Cache<CacheMeta>({
  cacheName: 'bitlabs-widget',
  hashSalt: 'L3m0N5qU33z3',
  provider: new WebCacheProvider(),
});
