<template>
  <svg width="0" height="0">
    <linearGradient :id="id" x1="0%" y1="100%" x2="100%" y2="0%">
      <stop offset="0%" :stop-color="gradientComponents.color1" class="linear-gradient__stop" />
      <stop offset="100%" :stop-color="gradientComponents.color2" class="linear-gradient__stop" />
    </linearGradient>
  </svg>
</template>

<script lang="ts" setup>
import { getLinearGradientComponents } from '@bitburst-gmbh/bitburst-colors';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useSettingsStore } from '../stores/settingsStore';

defineProps<{
  id: string;
}>();

const { settings } = storeToRefs(useSettingsStore());
const brandingColor = settings.value.strings.get('app.visual.light.survey_icon_color') ?? '#ffffff';
const gradientComponents = computed(() => {
  return Object.assign(
    { color1: brandingColor, color2: brandingColor, offset1: 0, offset2: 100, degrees: 0 },
    getLinearGradientComponents(brandingColor)
  );
});
</script>

<style lang="scss" scoped>
.linear-gradient__stop {
  stop-opacity: 1;
}
</style>
